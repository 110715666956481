<template>
  <div
    :id="`fade-up-${idSection}`"
    class="section"
    :style="`margin:${mg}!important`"
  >
    <b-row
      v-for="(item, index) in section"
      :key="index"
      :id="`fade-up-${idSection}-${index}`"
      class="justify-content-center text-center invisible"
      style="width: 100%; margin: 0"
    >
      <b-col v-if="item.primary.header.length > 0" cols="12">
        <h2 class="tepa-h2 border-red">{{ item.primary.header[0].text }}</h2>
      </b-col>
      <b-col v-if="item.primary.title.length > 0" cols="12">
        <h3 class="tepa-h3 section-title">
          {{ item.primary.title[0].text }}
        </h3>
      </b-col>
      <b-col
        v-if="item.primary.info.length > 0"
        class="section-info"
        col
        lg="8"
        md="8"
        sm="10"
        cols="11"
      >
        <prismic-rich-text class="tepa-p" :field="item.primary.info" />
      </b-col>
      <b-col
        v-if="item.primary.readmore"
        :id="`readmore-${idSection}-${index}`"
        col
        lg="8"
        md="8"
        sm="10"
        cols="11"
        class="text-right invisible"
      >
        <span class="section-link" @click="toPage(item.primary.link[0].text)"
          >+ Read more</span
        >
      </b-col>
    </b-row>
  </div>
</template>

<script>
import $ from "jquery";
export default {
  props: ["tag", "mg", "dataList", "idSection"],
  data() {
    return {
      header: null,
      title: null,
      section: [],
      readmore: false,
      link: null,
    };
  },
  computed: {
    ref() {
      return this.$store.getters["cms/ref"];
    },
  },
  mounted() {
    if (this.tag) {
      this.getContentSection();
    } else {
      this.setContentSection(this.dataList);
    }
    const me = this;
    console.log(me.idSection);
    $(document).scroll(function () {
      const height = $(window).scrollTop() + $(window).height();
      me.section.forEach((_, index) => {
        if ($(`#fade-up-${me.idSection}-${index}`).length > 0) {
          // if (me.idSection === "tepa-garden") {
          //   console.log($(`#fade-up-${me.idSection}`).offset().top, height);
          // }
          if (height > $(`#fade-up-${me.idSection}-${index}`).offset().top) {
            $(`#fade-up-${me.idSection}-${index}`)
              .addClass("animate__animated animate__fadeInUp")
              .removeClass("invisible");
          }
          if ($(`#readmore-${me.idSection}-${index}`).length > 0) {
            if (height > $(`#readmore-${me.idSection}-${index}`).offset().top) {
              $(`#readmore-${me.idSection}-${index}`)
                .addClass("animate__animated animate__flipInX")
                .removeClass("invisible");
            }
          }
        }
      });
    });
  },
  methods: {
    async getContentSection() {
      const params = {
        ref: this.ref,
        q: `[[at(document.tags, ["${this.tag}"])]]`,
      };
      const res = await this.$http.get(
        process.env.VUE_APP_URL + "/api/v2/documents/search",
        { params }
      );
      if (res.status === 200) {
        const data = res.data.results[0].data.body;
        this.setContentSection(data);
      }
    },
    setContentSection(data) {
      if (!data.length > 0) {
        data = [{ primary: data }];
      }
      data.forEach((arr) => {
        arr.primary.info[0].text = arr.primary.info[0].text.replaceAll(
          "/*start*/",
          `<span class="text-red">`
        );
        arr.primary.info[0].text = arr.primary.info[0].text.replaceAll(
          "/*end*/",
          `</span>`
        );
      });
      this.section = data;
    },
    toPage(url) {
      if (url.startsWith("http")) {
        let win = window.open(url, "_blank");
        win.focus();
      } else {
        this.$router.push(url);
      }
    },
  },
};
</script>

<style scoped>
.section {
  word-wrap: break-word;
}
.section-title {
  margin: 40px 0 0px !important;
}
.section-info {
  margin: 30px 0 25px;
}
.tepa-h3 {
  font-weight: 700 !important;
}
.tepa-h2 {
  padding-bottom: 15px;
  display: inline-block;
  border-bottom: 2px solid;
}
.section-link {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  cursor: pointer;
  text-decoration-line: underline;

  color: #740b1b;
}
@media only screen and (max-width: 992px) {
  .tepa-h2 {
    white-space: pre-line;
  }
  .section-info {
    margin: 10px 0 0px;
  }
  .section-link {
    font-size: 14px;
  }
}
</style>